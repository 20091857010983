// Layout ========================================================================

export const COMMUNITY_LOGO_URLS = {
  default: 'https://ogon.team/images/logo/152.png',
  'Клуб Лидеров Москва': '/images/communities/leaders-club.png',
};

// Theme =========================================================================

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
};
export const lightTheme = (theme) => theme.palette.mode === 'light';
export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;

export const MAX_RESIDENT_JOBS = 5;
export const MAX_RESIDENT_EDUCATIONS = 5;
export const MAX_RESIDENT_PROJECTS = 5;

export const MAX_RESIDENT_STATUS_COUNT = 10;

export const MAX_EXTRA_ACTUAL_REQUESTS = 2;

export const DEFAULT_AVATAR =
  '/images/communities/default-community-avatar.png';
export const DEFAULT_AVATAR_BACKGROUND =
  'linear-gradient(0deg,var(--primary-8, rgba(57, 78, 202, 0.08)) 0%,var(--primary-8, rgba(57, 78, 202, 0.08)) 100%),var(--background-default, #fff)';

export const DEFAULT_PALETTE_COLORS = [
  '#394ECA',
  '#2D77FC',
  '#8E33FF',
  '#FE3CA4',
  '#FE3C3C',
  '#FF6B00',
  '#FFAB00',
  '#22C55E',
  '#22BBC5',
  '#77798A',
];

export const COMMUNITY_PAGE_ALPHA_MIN = 0.1;
export const COMMUNITY_PAGE_ALPHA_MAX = 0.16;

export const PAYMENT_PERIODS = [
  {
    label: 'Месяц',
    value: 1,
  },
  {
    label: '3 месяца',
    value: 3,
  },
  {
    label: '6 месяцев',
    value: 6,
  },
  {
    label: '9 месяцев',
    value: 9,
  },
  {
    label: 'Год',
    value: 12,
  },
];
export const PAYMENT_PRICE_DEFAULT = 5000;
export const PAYMENT_PERIOD_DEFAULT = 3;
