import { Add } from '@mui/icons-material';
import { AppButton } from '../../../../components/ui/AppButton/AppButton';
import styles from './CommunitiesList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RelatedCommunity } from '../RelatedCommunity/RelatedCommunity';
import {
  fetchRelatedPage,
  removeRelatedCommunity,
} from '../../store/related-communities';
import { useCallback, useEffect, useRef, useState } from 'react';
import { RemoveConfirmation } from '../../../../components/ui/RemoveConfirmation/RemoveConfirmation';
import { Box, CircularProgress } from '@mui/material';

export const CommunitiesList = () => {
  const { communities, loading, page, noMore } = useSelector(
    (state) => state.relatedCommunities
  );
  const [nextPage, setNextPage] = useState(2);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [community, setCommunity] = useState(null);
  const listRef = useRef();

  const openCommunity = (ev, community) => {
    ev.stopPropagation();
    window.open(
      `${process.env.REACT_APP_UNAUTH_REDIRECT}community/${community.id}`,
      '_blank'
    );
  };

  const editCommunity = (ev, community) => {
    ev.stopPropagation();
    navigate(`/club/related/${community.id}`);
  };

  const removeCommunity = (ev, community) => {
    setCommunity(community);
    ev.stopPropagation();
  };

  const proceedRemove = () => {
    dispatch(removeRelatedCommunity(community.id));
    setCommunity(null);
  };

  const listScrolled = useCallback(
    (ev) => {
      const elem = ev.target;
      const height =
        elem.children[0].getBoundingClientRect().height - 300 - 250;
      const scroll = elem.scrollTop;
      console.log(0.5, Date.now(), { scroll, height, nextPage, page });
      if (!noMore && scroll > height && nextPage >= page) {
        const now = Date.now();
        console.log(2, Date.now());
        if (window.relatedLastLoaded && now - window.relatedLastLoaded < 1000)
          return;
        console.log(2, Date.now());
        dispatch(fetchRelatedPage());
        setNextPage(nextPage + 1);
        window.relatedLastLoaded = Date.now();
      }
    },
    [dispatch, loading]
  );

  useEffect(() => {
    if (!listRef.current) return;
    listRef.current.addEventListener('scroll', listScrolled, false);
  }, [listRef, listScrolled]);

  return (
    <>
      <div className={styles.list}>
        <div className={styles.description}>
          Вам доступно создание дополнительных связанных сообществ. Данные об
          участниках и событиях этих сообществ будут доступны в вашей
          админ-панели.
        </div>
        <div className={styles.button}>
          <Link to="/club/related/add/">
            <AppButton startIcon={<Add />} color="secondary">
              Создать связанное сообщество
            </AppButton>
          </Link>
        </div>
        <div className={styles.list} ref={listRef}>
          {!communities.length && (
            <div className={styles.placeholder}>
              У вас пока нет связанных сообществ
            </div>
          )}
          <div className={styles.communities}>
            {communities.map((community) => (
              <RelatedCommunity
                title={community.title}
                managers={community.managers}
                onOpen={(ev) => openCommunity(ev, community)}
                onEdit={(ev) => editCommunity(ev, community)}
                onRemove={(ev) => removeCommunity(ev, community)}
              />
            ))}
          </div>
          {!noMore && loading && (
            <Box sx={{ paddingTop: 2, textAlign: 'center', height: '50px' }}>
              <CircularProgress size="20px" />
            </Box>
          )}
        </div>
      </div>

      {community && (
        <RemoveConfirmation
          title={`Удалить сообщество «${community.title}»?`}
          onCancel={() => {
            setCommunity(null);
          }}
          onConfirm={proceedRemove}
        >
          Вы точно хотите удалить сообщество «{community.title}»? Это действие
          нельзя будет отменить.
        </RemoveConfirmation>
      )}
    </>
  );
};
